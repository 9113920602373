(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

$(function () {
  // Post contact form ajax
  $("#contact").submit(function (e) {

    var url = baseUrl + "/contact"; // the script where you handle the form input.

    $('#contactSubmit').attr('disabled', 'disabled');
    $('#formSuccess').addClass('hidden');

    $.ajax({
      type: "POST",
      url: url,
      data: $("#contact").serialize(), // serializes the form's elements.
      success: function success(data) {
        $('#formSuccess').removeClass('hidden');
        $('#contactSubmit').attr('disabled', false);
        $('#contactName').val('');
        $('#contactEmail').val('');
        $('#contactSubject').val('');
        $('#contactMessage').val('');
      },
      error: function error(data) {
        var formErrors = res.responseJSON;

        $('#contactSubmit').attr('disabled', false);

        if (formErrors.hasOwnProperty('name')) {
          $('#contactName').addClass('has-error');
        } else {
          $('#contactName').removeClass('has-error');
        }
        if (formErrors.hasOwnProperty('email')) {
          $('#contactEmail').addClass('has-error');
        } else {
          $('#contactEmail').removeClass('has-error');
        }
        if (formErrors.hasOwnProperty('subject')) {
          $('#contactSubject').addClass('has-error');
        } else {
          $('#contactSubject').removeClass('has-error');
        }
        if (formErrors.hasOwnProperty('message')) {
          $('#contactMessage').addClass('has-error');
        } else {
          $('#contactMessage').removeClass('has-error');
        }
      }
    });

    e.preventDefault(); // avoid to execute the actual submit of the form.
  });
});

},{}]},{},[1]);
